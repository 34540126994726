import { Event } from "../events/EventDispatcher";
import { SceneEvent } from "../SceneEvent";
import { DataStorage } from '../DataStorage';
import { gsap, Power1 } from "gsap";

export class HeaderView
{
	private element: HTMLElement;
	
	private menu: HTMLElement;
	private opener: HTMLElement;
	private logo: HTMLElement;

	private l0: HTMLElement;
	private l1: HTMLElement;

	private isOpen: boolean = false;

	private addBindThis: Function;
	private removeBindThis: Function;
	private addBackToTopBindThis: Function;
	private removeBackToTopBindThis: Function;
	private offBindThis: Function;

	constructor()
	{
		this.element = document.querySelector("#header");
		
		this.menu = this.element.querySelector("._menu");
		this.opener = this.element.querySelector("._opener");
		this.logo = this.element.querySelector("._logo");

		this.l0 = this.opener.querySelector("._l0");
		this.l1 = this.opener.querySelector("._l1");

		this.opener.addEventListener('click', this.onClick.bind(this));
		
		this.element.querySelector('._backToTop').addEventListener('click', (event) =>
		{
			if(DataStorage.PAGE=="top")
			{
				DataStorage.state = "top";

				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_COVER));		//クリック禁止

				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.REMOVE_EPISODE));
				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.REMOVE_HISTORY));
				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.BOOK_CLOSE));
			}
			else
			{
				location.href = DataStorage.INDEX_URL+"?page=top";
			}
		});
		
		this.element.querySelector('._top').addEventListener('click', (event) =>
		{
			if(DataStorage.PAGE=="top")
			{
				DataStorage.state = "top";
				
				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_COVER));		//クリック禁止

				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.BACK_TO_TOP));
				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.REMOVE_EPISODE));
				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.REMOVE_HISTORY));
				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.BOOK_CLOSE));
				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.REMOVE_SEND_YOURHISTORY));

				this.close();
			}
			else
			{
				location.href = DataStorage.INDEX_URL+"?page=top";
			}
		});
		
		this.element.querySelector('._history').addEventListener('click', (event) =>
		{
			if(DataStorage.PAGE=="top")
			{
				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_COVER));		//クリック禁止

				DataStorage.quickBookOpen = "history";

				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.BACK_TO_TOP));
				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.REMOVE_EPISODE));
				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.REMOVE_HISTORY));
				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.BOOK_CLOSE));
				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.REMOVE_SEND_YOURHISTORY));

				this.close();
			}
			else
			{
				location.href = DataStorage.INDEX_URL+"?page=top&quickBookOpen=history";
			}
		});
		
		this.element.querySelectorAll('._category').forEach((element) =>
		{
			element.addEventListener('click', (event) =>
			{
				if(DataStorage.PAGE=="top")
				{
					SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_COVER));		//クリック禁止

					DataStorage.quickBookOpen = (element as HTMLElement).dataset.id;
	
					SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.BACK_TO_TOP));
					SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.REMOVE_EPISODE));
					SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.REMOVE_HISTORY));
					SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.BOOK_CLOSE));
					SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.REMOVE_SEND_YOURHISTORY));
	
					this.close();
				}
				else
				{
					location.href = DataStorage.INDEX_URL+"?page=top&quickBookOpen="+(element as HTMLElement).dataset.id;
				}
			});
		});
		
		this.element.querySelector('._sendYourhistory').addEventListener('click', (event) =>
		{
			if(DataStorage.PAGE=="top")
			{
				if(DataStorage.yourhistoryEpisode.length>0)
				{
					SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_SEND_YOUR_HISTORY_MODAL));
				}
				else
				{
					SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_SEND_YOURHISTORY));
				}
				this.close();
			}
			else
			{
				location.href = DataStorage.INDEX_URL+"?page=sendYourhistory";
			}
		});

		this.addBindThis = this.add.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.ADD_HEADER, this.addBindThis);

		this.removeBindThis = this.remove.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.REMOVE_HEADER, this.removeBindThis);

		this.addBackToTopBindThis = this.addBackToTop.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.ADD_EPISODE, this.addBackToTopBindThis);
		SceneEvent.getInstance().addEventListener(SceneEvent.GET_EPISODE_BY_CATEGORY_ID_COMPLETE, this.addBackToTopBindThis);
		SceneEvent.getInstance().addEventListener(SceneEvent.BOOK_OPEN_COMPLETE, this.addBackToTopBindThis);
		SceneEvent.getInstance().addEventListener(SceneEvent.ADD_MESSAGE_CHECK, this.addBackToTopBindThis);

		this.removeBackToTopBindThis = this.removeBackToTop.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.REMOVE_EPISODE, this.removeBackToTopBindThis);
		SceneEvent.getInstance().addEventListener(SceneEvent.ADD_SEND_YOURHISTORY, this.removeBackToTopBindThis);
		SceneEvent.getInstance().addEventListener(SceneEvent.REMOVE_MESSAGE_CHECK, this.removeBackToTopBindThis);

		this.offBindThis = this.off.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.MENU_OFF, this.offBindThis);
		
		if(DataStorage.PAGE=="faq")
		{
			this.addBackToTop();
		}
	}
	public add(): void
	{
		gsap.to(this.element, 0.9, {css:{display:"block", opacity:1}, ease:Power1.easeInOut});

	}
	public onClick(): void
	{
		if(this.isOpen)
		{
			this.close();
		}
		else
		{
			this.open();
		}
	}
	public open(): void
	{
		this.isOpen = true;

		gsap.killTweensOf(this.menu);
		gsap.killTweensOf(this.logo);
		gsap.killTweensOf(this.l0);
		gsap.killTweensOf(this.l1);
		
		gsap.to(this.menu, 0.3, {css:{display:"block", height:DataStorage.window_w/750*880}, ease:Power1.easeInOut});
		gsap.to(this.logo, 0.3, {css:{fill:"#ffffff"}, ease:Power1.easeOut});

		gsap.to(this.l0, 0.3, {css:{marginTop:0, rotation:30, backgroundColor:"#ffffff"}, ease:Power1.easeOut});
		gsap.to(this.l1, 0.3, {css:{marginTop:0, rotation:-30, backgroundColor:"#ffffff"}, ease:Power1.easeOut});

	}
	public close(): void
	{
		this.isOpen = false;

		gsap.killTweensOf(this.menu);
		gsap.killTweensOf(this.logo);
		gsap.killTweensOf(this.l0);
		gsap.killTweensOf(this.l1);

		gsap.to(this.menu, 0.3, {css:{display:"none", height:0}, ease:Power1.easeInOut});
		gsap.to(this.logo, 0.3, {css:{fill:"#585858"}, ease:Power1.easeOut});

		gsap.to(this.l0, 0.3, {css:{marginTop:DataStorage.window_w/750*6, rotation:0, backgroundColor:"#585858"}, ease:Power1.easeOut});
		gsap.to(this.l1, 0.3, {css:{marginTop:-DataStorage.window_w/750*6, rotation:0, backgroundColor:"#585858"}, ease:Power1.easeOut});
		
	}
	public remove(): void
	{
		gsap.set(this.element, {css:{display:"none"}});

	}
	public addBackToTop(): void
	{
		gsap.killTweensOf(this.element.querySelector("._backToTop"));
		gsap.to(this.element.querySelector("._backToTop"), 0.6, {css:{display:"block", opacity:1}, ease:Power1.easeInOut, delay:0.6});

	}
	public removeBackToTop(): void
	{
		gsap.killTweensOf(this.element.querySelector("._backToTop"));
		gsap.set(this.element.querySelector("._backToTop"), {css:{display:"none", opacity:0}});

	}
	public off(): void
	{
		this.element.querySelector('._top').classList.remove("off");
		this.element.querySelector('._history').classList.remove("off");
		this.element.querySelector('._category_1').classList.remove("off");
		this.element.querySelector('._category_2').classList.remove("off");
		this.element.querySelector('._category_3').classList.remove("off");
		this.element.querySelector('._category_4').classList.remove("off");
		this.element.querySelector('._category_5').classList.remove("off");
		this.element.querySelector('._sendYourhistory').classList.remove("off");

		switch(DataStorage.offMenu)
		{
			case "top":
				this.element.querySelector('._top').classList.add("off");
				break;
			case "history":
				this.element.querySelector('._history').classList.add("off");
				break;
			case "category_1":
				this.element.querySelector('._category_1').classList.add("off");
				break;
			case "category_2":
				this.element.querySelector('._category_2').classList.add("off");
				break;
			case "category_3":
				this.element.querySelector('._category_3').classList.add("off");
				break;
			case "category_4":
				this.element.querySelector('._category_4').classList.add("off");
				break;
			case "category_5":
				this.element.querySelector('._category_5').classList.add("off");
				break;
			case "sendYourhistory":
				this.element.querySelector('._sendYourhistory').classList.add("off");
				break;
		}
	}
}