import GUI from 'lil-gui';

export class DataStorage
{
	public static isDebug: boolean = false;
	public static cookies_expires: number = 1/24/60*10;

	public static PAGE: string = "";						//top, message, faq
	public static LIFF_ID: string = "";
	public static QUICK_LINK: string = "";					//top, sendYourhistory

	//ステージング
	public static API_PATH: string = "https://d2wc6glaol856n.cloudfront.net";
	public static INDEX_ID: string = "1656936137-3126nXBa";
	public static MESSAGE_ID: string = "1656936137-1aElbx4o";
	public static FAQ_ID: string = "1656936137-bAEQNzW8";
	public static ENQUETE_ID: string = "1656936137-woNEvnx5";

	//本番
	// public static API_PATH: string = "https://d2cxbvo9y3ffsv.cloudfront.net";
	// public static INDEX_ID: string = "1654200875-JeNOnWK9";
	// public static MESSAGE_ID: string = "1654200875-m9Q2zxyK";
	// public static FAQ_ID: string = "1654200875-rEVoaBqO";
	// public static ENQUETE_ID: string = "1654200875-75G9gv8B";

	public static INDEX_URL: string = "https://liff.line.me/"+DataStorage.INDEX_ID;
	public static MESSAGE_URL: string = "https://liff.line.me/"+DataStorage.MESSAGE_ID;
	public static FAQ_URL: string = "https://liff.line.me/"+DataStorage.FAQ_ID;
	public static ENQUETE_URL: string = "https://liff.line.me/"+DataStorage.ENQUETE_ID;
	
	public static init_w: number;
	public static init_h: number;
	public static window_w: number;							//ウィンドウ幅
	public static window_h: number;							//ウィンドウ高さ
	public static scrollTop: number = 0;
	public static scrollLeft: number = 0;

	public static isSP: boolean;							//スマホかどうか
	public static hasEpisode: boolean = false;
	public static isBookOpen: boolean = false;
	public static state: string = "top";					//top, usersStory, history
	public static quickBookOpen: string = "";				//category_1, category_2, category_3, category_4, category_5, history

	public static pageNum: number = 0;
	public static pageNumMax: number = 0;

	public static accessToken: string = "";
	public static episodeId: number;
	public static episodeNo: number;
	public static episodeText: string = "";
	public static categoryId: number;

	public static offMenu: string = "top";					//top, history, category_1, category_2, category_3, category_4, category_5, sendYourhistory

	// API
	public static episode: any;
	public static yourhistoryEpisode: any;
	public static usersstoryEpisode: any;
	public static usersstoryCategory: any;

	public static prefectures: number = 0;
	public static prefecturesName: string[] = 
	[
		"",
		"北海道 / Hokkaido",
		"青森県 / Aomori",
		"岩手県 / Iwate",
		"宮城県 / Miyagi",
		"秋田県 / Akita",
		"山形県 / Yamagata",
		"福島県 / Fukushima",
		"茨城県 / Ibaraki",
		"栃木県 / Tochigi",
		"群馬県 / Gunma",
		"埼玉県 / Saitama",
		"千葉県 / Chiba",
		"東京都 / Tokyo",
		"神奈川県 / Kanagawa",
		"新潟県 / Niigata",
		"富山県 / Toyama",
		"石川県 / Ishikawa",
		"福井県 / Fukui",
		"山梨県 / Yamanashi",
		"長野県 / Nagano",
		"岐阜県 / Gifu",
		"静岡県 / Shizuoka",
		"愛知県 / Aichi",
		"三重県 / Mie",
		"滋賀県 / Shiga",
		"京都府 / Kyoto",
		"大阪府 / Osaka",
		"兵庫県 / Hyogo",
		"奈良県 / Nara",
		"和歌山県 / Wakayama",
		"鳥取県 / Tottori",
		"島根県 / Shimane",
		"岡山県 / Okayama",
		"広島県 / Hiroshima",
		"山口県 / Yamaguchi",
		"徳島県 / Tokushima",
		"香川県 / Kagawa",
		"愛媛県 / Ehime",
		"高知県 / Kochi",
		"福岡県 / Fukuoka",
		"佐賀県 / Saga",
		"長崎県 / Nagasaki",
		"熊本県 / Kumamoto",
		"大分県 / Oita",
		"宮崎県 / Miyazaki",
		"鹿児島県 / Kagoshima",
		"沖縄県 / Okinawa",
		"その他 / Other",
	];

	public static qr_type: number = 0;

	public static gui: GUI | null;

	//ヒストリー
	public static historyNum: number = 0;
	public static historyPointPosition: any = 
	{
		"x": 0,
		"y": 0,
		"radius": 0,
		"red": 0,
		"start": 0
	};
	public static historyData: any[] = 
	[
		{
			'photo': '1974.jpg',
			'year': '1974',
			'ja': '原宿パレ フランスに、カルティエの日本初のブティックがオープン',
			'en': 'FIRST BOUTIQUE AT PALAIS FRANCE, HARAJUKU',
			'credit': 'Documentation Cartier Paris © Cartier',
		},
		{
			'photo': '1977.jpg',
			'year': '1977',
			'ja': '「タンク マスト ドゥ カルティエ」ウォッチの発売',
			'en': 'MUST TANK',
			'credit': 'N. Welsh, Collection Cartier © Cartier',
		},
		{
			'photo': '1991.jpg',
			'year': '1991',
			'ja': 'カルティエ 銀座並木通りブティック オープン',
			'en': 'CARTIER GINZA NAMIKI BOUTIQUE',
			'credit': '© Cartier',
		},
		{
			'photo': '1995.jpg',
			'year': '1995',
			'ja': '『カルティエ コレクション-絢爛のジュエリー フランス宝飾芸術の\n世界展』を東京都庭園美術館にて開催',
			'en': 'EXHIBITON "ART OF CARTIER" AT METROPOTIAN TEIEN MUSEUM, TOKYO',
			'credit': '© Tokyo Metropolitan Teien Art Museum/ Cartier Photo : © Louis Tirilly',
		},
		{
			'photo': 'null.png',
			'year': '2000',
			'ja': 'カルティエ 心斎橋ブティック オープン',
			'en': 'CARTIER SHINSAIBASHI BOUTIQUE',
			'credit': '',
		},
		{
			'photo': '2002.jpg',
			'year': '2002',
			'ja': '日韓ワールドカップを記念して制作された限定モデル',
			'en': 'PASHA DE CARTIER LIMITED EDITION',
			'credit': 'Archives Cartier Paris © Cartier',
		},
		{
			'photo': 'null.png',
			'year': '2003',
			'ja': 'カルティエ 銀座二丁目ブティック（現カルティエ 銀座ブティック）\nオープン',
			'en': 'CARTIER GINZA 2-CHOME BOUTIQUE',
			'credit': '',
		},
		{
			'photo': 'null.png',
			'year': '2004',
			'ja': '醍醐寺 霊宝館にて、『エットレ ソットサスの目がとらえたカルティエ\n宝飾デザイン』展を開催',
			'en': 'EXHIBITION "CARTIER CREATIONS VIEWED BY ETTORE SOTTSASS" AT DAIGOJI, KYOTO',
			'credit': '',
		},
		{
			'photo': '2005.jpg',
			'year': '2005',
			'ja': 'カルティエ 南青山ブティック オープン',
			'en': 'CARTIER MINAMI AOYAMA BOUTIQUE',
			'credit': 'Philippe Gontier © Cartier',
		},
		{
			'photo': 'null.png',
			'year': '2006',
			'ja': '『カルティエ現代美術財団コレクション』展を東京都現代美術館で開催',
			'en': 'EXHIBITION "FONDATION CARTIER POUR L\'ART CONTEMPORAIN" AT MOT, TOKYO',
			'credit': '',
		},
		{
			'photo': 'null.png',
			'year': '2007',
			'ja': 'カルティエ 名古屋ブティック オープン',
			'en': 'CARTIER NAGOYA BOUTIQUE',
			'credit': '',
		},
		{
			'photo': '2008.jpg',
			'year': '2008',
			'ja': '東京 三井倶楽部にてジュエリー コレクション「インド ミステリューズ」\nローンチイベント開催',
			'en': '"INDYMISTERIES" EVENT',
			'credit': '© Cartier',
		},
		{
			'photo': '2009.jpg',
			'year': '2009',
			'ja': '『Story of… カルティエ クリエイション～めぐり逢う美の記憶』\n展を東京国立博物館 表慶館で開催',
			'en': 'EXHIBITION "STORY OF... MEMORIES OF CARTIER CREATIONS" AT TOKYO NATIONAL MUSEUM',
			'credit': '© O Yoshioka/ Tokyo National Museum/ Cartier Photo : Nick Welsh, Collection Cartier © Cartier',
		},
		{
			'photo': '2015.jpg',
			'year': '2015',
			'ja': '日本初のインターナショナル ハイジュエリーイベント「カルティエ \nロワイヤル」を京都で開催',
			'en': '"CARTIER ROYAL" HIGH JEWELRY EVENT IN KYOTO',
			'credit': '© Cartier',
		},
		{
			'photo': '2016-1.jpg',
			'year': '2016',
			'ja': '2年間の改築を経て、カルティエ 銀座ブティックがオープン',
			'en': 'THE NEW CARTIER GINZA BOUTIQUE',
			'credit': '© Jimmy Cohrssen',
		},
		{
			'photo': '2016-2.jpg',
			'year': '2016',
			'ja': '東京でハイジュエリーイベント「マジシャン」を開催',
			'en': '"CARTIER MAGICIAN" HIGH JEWERLY EVENT IN TOKYO',
			'credit': 'MasahikoTakeda © Cartier',
		},
		{
			'photo': '2017.jpg',
			'year': '2017',
			'ja': 'カルティエ ブティック 六本木ヒルズ店がオープン',
			'en': 'CARTIER ROPPONGI HILLS BOUTIQUE',
			'credit': '© Jimmy Cohrssen',
		},
		{
			'photo': '2018.jpg',
			'year': '2018',
			'ja': 'ジュエリーコレクション「ジュスト アン クル」のPop-up「カルチエ \nコンビニ」が期間限定オープン',
			'en': '"CARTIER KONVINI" POP-UP EVENT IN HARAJUKU',
			'credit': '© Cartier',
		},
		{
			'photo': '2019-1.jpg',
			'year': '2019',
			'ja': '原宿にてジュエリーコレクション「クラッシュ ドゥ カルティエ」の\nPop-upイベントを開催',
			'en': '"CLASH DE CARTIER" POP-UP EVENT IN HARAJUKU',
			'credit': '© Cartier',
		},
		{
			'photo': '2019-2.jpg',
			'year': '2019',
			'ja': '『カルティエ、時の結晶』展を国立新美術館にて開催',
			'en': 'EXHIBITION "CARTIER, CRYSTALLIZATION OF TIME" AT NATIONAL ART CENTER, TOKYO',
			'credit': 'Yuji Ono © Nikkei_Cartier 2019/ring Cartier, 2013, private collection',
		},
		{
			'photo': '2021-1.jpg',
			'year': '2021',
			'ja': '『横尾忠則：The Artists』展を21_21 DESIGN SIGHT ギャラリー3\nにて開催',
			'en': 'EXHIBITION TADANORI YOKOO "THE ARTISTS" AT 21_21 DESIGN SIGHT',
			'credit': '© Tadanori Yokoo',
		},
		{
			'photo': '2021-2.jpg',
			'year': '2021',
			'ja': '六本木・表参道・丸の内で「LOVE IS ALL」のPop-upを開催',
			'en': '"LOVE IS ALL" POP-UP EVENT',
			'credit': '© Cartier',
		},
		{
			'photo': '2022-1.jpg',
			'year': '2022',
			'ja': 'インターナショナル ハイジュエリーイベント「シジエムサンス パル \nカルティエ」を京都にて開催',
			'en': '"SIXIEM SENS PAR CARTIER" HIGH JEWELEY EVENT IN KYOTO',
			'credit': '© Cartier',
		},
		{
			'photo': '2022-2.jpg',
			'year': '2022',
			'ja': '「Trinity for Chitose Abe of sacai」 日本限定モデル発売',
			'en': '"TRINITY FOR CHITOSE ABE OF SACAI" JAPAN LIMITED EDITION',
			'credit': '© Cartier',
		},
		{
			'photo': '2022-3.jpg',
			'year': '2022',
			'ja': 'カルティエ ブティック GINZA SIX 店オープン',
			'en': 'CARTIER GINZA SIX BOUTIQUE',
			'credit': '© Cartier',
		},
		{
			'photo': '2022-4.jpg',
			'year': '2022',
			'ja': '表参道・六本木にてクリスマス Pop-upイベント「カルティエ座」\nを開催',
			'en': '"END OF YEAR" POP-UP EVENT',
			'credit': '© Cartier',
		},
		{
			'photo': '2023-1.jpg',
			'year': '2023',
			'ja': 'ジュエリーコレクション「クラッシュ ドゥ カルティエ」のPop-up\nイベントを六本木ヒルズで開催',
			'en': '"CLASH DE CARTIER" POP-UP EVENT AT ROPPONGI HILLS',
			'credit': '© Cartier',
		},
		{
			'photo': '2023-2.jpg',
			'year': '2023',
			'ja': 'ウォッチ Pop-upイベント「TIME UNLIMITED」を原宿で開催',
			'en': '"TIME UNLIMITED" WATCH POP-UP EVENT IN HARAJUKU',
			'credit': '© Cartier',
		},
		{
			'photo': '2024.jpg',
			'year': '2024',
			'ja': '「タンク フランセーズ」の新モデルが日本限定で発売',
			'en': 'TANK FRANÇAISE JAPAN LIMITED EDITION',
			'credit': '© Cartier',
		}
	];
	public static historyPointX: number[] = [];
}